/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React, { Component } from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import { Container } from "semantic-ui-react"

import Navigation from "./navigation"
import Header from "./header"
import Footer from "./footer"
import "./layout.css"

class Layout extends Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => (
          <>
            <Helmet>
              <script
                async
                src="https://www.googletagmanager.com/gtag/js?id=UA-128492818-1"
              />
              <link
                rel="stylesheet"
                href="//cdn.jsdelivr.net/npm/semantic-ui@2.4.2/dist/semantic.min.css"
              />
              <script
                defer
                src="https://cdn.jsdelivr.net/npm/jquery@3.3.1/dist/jquery.min.js"
              />
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1"
              />
              <link
                href="https://fonts.googleapis.com/css?family=Montserrat|Open+Sans"
                rel="stylesheet"
              />
              <script
                defer
                src="https://use.fontawesome.com/releases/v5.8.1/js/all.js"
                integrity="sha384-g5uSoOSBd7KkhAMlnQILrecXvzst9TdC09/VM+pjDTCM+1il8RHz5fKANTFFb+gQ"
                crossorigin="anonymous"
              />
              <script
                defer
                src="https://cdn.jsdelivr.net/npm/fomantic-ui@2.7.4/dist/semantic.min.js"
              />
            </Helmet>
            <Container fluid>
              <Header siteTitle={data.site.siteMetadata.title} />
              <Navigation />
              <main>{this.props.children}</main>
              <Footer />
            </Container>
          </>
        )}
      />
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
