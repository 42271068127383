import React, { Component } from "react"
import { Menu } from "semantic-ui-react"
import styled from "styled-components"
import classnames from "classnames"

import "./navigation.css"

const StyledMenu = styled(Menu)`
  margin-bottom: 0em !important;
  border: none !important;
  background: #024b6f !important;
  border-radius: 0px !important;
  box-shadow: 0px 0px 0px 0px black !important;
  justify-content: center;
  position: relative !important;
`

const StyledMenuItem = styled(Menu.Item)`
  &::before {
    background: none !important;
    border: none !important;
  }
  color: white !important;
  text-transform: uppercase !important;
  font-weight: bold !important;
  padding: 1.5em !important;
`

class Navigation extends Component {
  state = {
    hamburgerActive: false,
  }

  hamburgerToggle = () => {
    this.setState(prevState => ({
      hamburgerActive: !prevState.hamburgerActive,
    }))
  }

  handleItemClick = (e, { name }) => this.setState({ activeItem: name })

  render() {
    const { activeItem } = this.state

    return (
      <StyledMenu
        fluid
        centered
        stackable
        style={{ marginBottom: "0em" }}
        className={classnames("", {
          open: this.state.hamburgerActive,
        })}
      >
        <StyledMenuItem
          name="Home"
          active={activeItem === "Home"}
          onClick={this.handleItemClick}
          href="/"
        >
          Home
        </StyledMenuItem>
        <StyledMenuItem
          name="About"
          active={activeItem === "About"}
          onClick={this.handleItemClick}
          href="/about-us"
        >
          About Us
        </StyledMenuItem>
        <StyledMenuItem
          name="aboutmentors"
          active={activeItem === "aboutmentors"}
          onClick={this.handleItemClick}
          href="/about-hfe-mentors"
        >
          About HFE Mentors
        </StyledMenuItem>
        <StyledMenuItem
          name="agents"
          active={activeItem === "agents"}
          onClick={this.handleItemClick}
          href="/agents"
        >
          Agents
        </StyledMenuItem>
        <StyledMenuItem
          name="education"
          active={activeItem === "education"}
          onClick={this.handleItemClick}
          href="/education-providers"
        >
          Education Providers
        </StyledMenuItem>
        <StyledMenuItem
          name="organizations"
          active={activeItem === "organizations"}
          onClick={this.handleItemClick}
          href="/homestay-organizations"
        >
          Homestay Organizations
        </StyledMenuItem>
        <StyledMenuItem
          name="faqs"
          active={activeItem === "faqs"}
          onClick={this.handleItemClick}
          href="/faqs"
        >
          FAQs
        </StyledMenuItem>
        <StyledMenuItem
          name="contact"
          active={activeItem === "contact"}
          onClick={this.handleItemClick}
          href="/contact-us"
        >
          Contact Us
        </StyledMenuItem>
        <div
          className={classnames("hamburger", {
            active: this.state.hamburgerActive,
          })}
          onClick={this.hamburgerToggle}
        >
          <span class="hamburger-bun" />
          <span class="hamburger-patty" />
          <span class="hamburger-bun" />
        </div>
      </StyledMenu>
    )
  }
}

export default Navigation
