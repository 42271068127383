import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

const Image = styled.img`
  max-width: 210px;
  margin: auto;
`

const Header = ({ siteTitle }) => (
  <header>
    <div style={{ textAlign: "center", paddingTop: "1em"}}>
      <Link to="/">
        <Image src="https://res.cloudinary.com/mystay-international/image/upload/c_scale,w_210/v1555552271/Host%20Family%20English/Host-Family-English-logo-FINAL.png" />
      </Link>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
