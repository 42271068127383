import React from "react"
import { Grid, Container } from "semantic-ui-react"
import styled from "styled-components"

const StyledContainer = styled(Container)`
  padding: 5em 0em;
  box-shadow: 0px 0px 0px 0px black !important;
`
const FooterText = styled.p`
  color: #004165;
  font-size: 1.1em;
  font-weight: semi-bold;
`

const Footer = () => (
  <footer>
    <StyledContainer>
      <Grid>
        <Grid.Row>
          <Grid.Column textAlign="center">
            <FooterText>
              Copyright © Host Family English Limited (CRN 10915376)
            </FooterText>
            <FooterText><a href="https://mystayenglish.com/privacy-policy/">Privacy</a></FooterText>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </StyledContainer>
  </footer>
)

export default Footer
